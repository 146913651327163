import { lazy } from 'react';
import Loadable from 'components/Loadable';
import DashboardLayout from 'layout/Dashboard';
import PagesLayout from 'layout/Pages';
import SimpleLayout from 'layout/Simple';
import { SimpleLayoutType } from 'config';

const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/default')));
// const DashboardAnalytics = Loadable(lazy(() => import('pages/dashboard/analytics')));

const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));

// const MaintenanceError = Loadable(lazy(() => import('pages/example/maintenance/404')));
// const MaintenanceError500 = Loadable(lazy(() => import('pages/example/maintenance/500')));
// const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/example/maintenance/under-construction')));
// const MaintenanceComingSoon = Loadable(lazy(() => import('pages/example/maintenance/coming-soon')));

const ContactUS = Loadable(lazy(() => import('pages/contact-us')));

// const SamplePage = Loadable(lazy(() => import('pages/example/extra-pages/sample-page')));
// const PricingPage = Loadable(lazy(() => import('pages/example/extra-pages/pricing')));

const PagesLanding = Loadable(lazy(() => import('pages/landing')));
const SystemStatus = Loadable(lazy(() => import('pages/systemstatus')));
const LiveInventory = Loadable(lazy(() => import('pages/inventory/live')));
const ProductInfo = Loadable(lazy(() => import('pages/inventory/info')));
const HistoricLevels = Loadable(lazy(() => import('pages/inventory/history')));
const ProductDetails = Loadable(lazy(() => import('pages/inventory/product')));

//purchaseorders
const PurchaseList = Loadable(lazy(() => import('pages/purchase')));
// const OrderCalculations = Loadable(lazy(() => import('pages/orders/calc')));
// const PendingOrders = Loadable(lazy(() => import('pages/orders/pending')));

const CompanyPage = Loadable(lazy(() => import('pages/settings/company')));
const ListConfigurations = Loadable(lazy(() => import('pages/settings/configlist')));
const ListIntergrations = Loadable(lazy(() => import('pages/settings/integrationlist')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <SimpleLayout layout={SimpleLayoutType.LANDING} />,
      children: [
        {
          index: true,
          element: <PagesLanding />
        }
      ]
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'dashboard',
          children: [
            {
              path: 'default',
              element: <DashboardDefault />
            }
            // {
            //   path: 'analytics',
            //   element: <DashboardAnalytics />
            // }
          ]
        },
        {
          path: 'inventory',
          children: [
            {
              path: 'live',
              element: <LiveInventory />
            },
            {
              path: 'info',
              element: <ProductInfo />
            },
            {
              path: 'history',
              element: <HistoricLevels />
            },
            {
              path: 'productdetails',
              children: [
                {
                  path: ':sku',
                  element: <ProductDetails />
                }
              ]
            }
          ]
        },
        {
          path: 'purchase',
          children: [
            {
              path: 'list',
              element: <PurchaseList />
            }
          ]
        },
        {
          path: 'settings',
          children: [
            {
              path: 'company',
              element: <CompanyPage />
            },
            {
              path: 'devices',
              element: <ListConfigurations />
            },
            {
              path: 'integrations',
              element: <ListIntergrations />
            }
          ]
        },
        // {
        //   path: 'sample-page',
        //   element: <SamplePage />
        // },
        // {
        //   path: 'pricing',
        //   element: <PricingPage />
        // },
        {
          path: 'systemstatus',
          element: <SystemStatus />
        },
        {
          path: 'contact-us',
          element: <ContactUS />
        }
      ]
    },
    // {
    //   path: '/maintenance',
    //   element: <PagesLayout />,
    //   children: [
    //     {
    //       path: '404',
    //       element: <MaintenanceError />
    //     },
    //     {
    //       path: '500',
    //       element: <MaintenanceError500 />
    //     },
    //     {
    //       path: 'under-construction',
    //       element: <MaintenanceUnderConstruction />
    //     },
    //     {
    //       path: 'coming-soon',
    //       element: <MaintenanceComingSoon />
    //     }
    //   ]
    // },
    {
      path: '/auth',
      element: <PagesLayout />,
      children: [
        {
          path: 'login',
          element: <AuthLogin />
        },
        {
          path: 'register',
          element: <AuthRegister />
        },
        {
          path: 'forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: 'reset-password',
          element: <AuthResetPassword />
        },
        {
          path: 'check-mail',
          element: <AuthCheckMail />
        },
        {
          path: 'code-verification',
          element: <AuthCodeVerification />
        }
      ]
    }
  ]
};

export default MainRoutes;
