import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

// project import
import MainRoutes from './MainRoutes';
// import ExampleRoutes from './ExampleRoutes';
import LoginRoutes from './LoginRoutes';
// import ComponentsRoutes from './ComponentsRoutes';
import AdminRoutes from './AdminRoutes';

import Loadable from 'components/Loadable';

// render - landing page
const PagesLanding = Loadable(lazy(() => import('pages/landing')));

// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter(
  [
    {
      path: '/',
      children: [
        {
          index: true,
          element: <PagesLanding />
        }
      ]
    },
    LoginRoutes,
    // ComponentsRoutes,
    MainRoutes,
    // ExampleRoutes,
    AdminRoutes
  ],
  { basename: process.env.REACT_APP_BASE_NAME }
);

export default router;
