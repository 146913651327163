// project import
import { useGetMenu } from 'api/menu';

// assets
import {
  DashboardOutlined,
  GoldOutlined,
  LoadingOutlined,
  ShopOutlined,
  UserOutlined,
  ApiOutlined,
  SettingOutlined,
  HomeOutlined,
  BarChartOutlined,
  PlayCircleOutlined,
  FileSearchOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  PoweroffOutlined,
  DollarOutlined,
  PhoneOutlined,
  ContainerOutlined,
  UnorderedListOutlined,
  ControlOutlined,
  AppstoreAddOutlined,
  LaptopOutlined
} from '@ant-design/icons';

const icons = {
  dashboard: DashboardOutlined,
  components: GoldOutlined,
  loading: LoadingOutlined,
  shop: ShopOutlined,
  user: UserOutlined,
  userAdd: AppstoreAddOutlined,
  integration: ApiOutlined,
  log: ContainerOutlined,
  config: ControlOutlined,
  setting: SettingOutlined,
  device: LaptopOutlined,
  home: HomeOutlined,
  barchart: BarChartOutlined,
  inventory: PlayCircleOutlined,
  search: FileSearchOutlined,
  clock: ClockCircleOutlined,
  check: CheckCircleOutlined,
  poweroff: PoweroffOutlined,
  dollar: DollarOutlined,
  phone: PhoneOutlined,
  company: ShopOutlined,
  list: UnorderedListOutlined
};

const loadingMenu = {
  id: 'group-loading',
  title: 'RFIDHub Menu Loading',
  type: 'group',
  icon: icons.loading
};

// ==============================|| MENU ITEMS - API ||============================== //

export const MenuFromAPI = () => {
  const { menu, menuLoading } = useGetMenu();

  if (menuLoading || !menu) return loadingMenu;
  var menuList = [];
  for (var i = 0; i < menu.length; i++) {
    menuList[i] = processItem(menu[i]);
  }
  return menuList;
};

function processItem(menu) {
  const subChildrenList = (children) => {
    return children?.map((subList) => {
      return fillItem(subList);
    });
  };

  const itemList = (subList) => {
    let list = fillItem(subList);

    // if collapsible item, we need to feel its children as well
    if (subList.type === 'collapse') {
      list.children = subChildrenList(subList.children);
    }
    return list;
  };

  const childrenList = menu?.children?.map((subList) => {
    return itemList(subList);
  });

  let menuList = fillItem(menu, childrenList);
  return menuList;
}

function fillItem(item, children) {
  return {
    ...item,
    title: item?.title, //<FormattedMessage id={`${item?.title}`} />,
    // @ts-ignore
    icon: icons[item?.icon],
    ...(children && { children })
  };
}
