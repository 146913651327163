import { lazy } from 'react';
import Loadable from 'components/Loadable';
import DashboardLayout from 'layout/Dashboard';

const AdminCompany = Loadable(lazy(() => import('pages/admin/company')));
const AdminCompanyView = Loadable(lazy(() => import('pages/admin/companyview')));
const AdminUser = Loadable(lazy(() => import('pages/admin/user')));
const AdminUserView = Loadable(lazy(() => import('pages/admin/userview')));
const AdminDevice = Loadable(lazy(() => import('pages/admin/device')));
const AdminDeviceView = Loadable(lazy(() => import('pages/admin/deviceview')));
const AdminIntegration = Loadable(lazy(() => import('pages/admin/integration')));
const AdminIntegrationView = Loadable(lazy(() => import('pages/admin/integrationview')));
const AdminConfiguration = Loadable(lazy(() => import('pages/admin/configuration')));
const AdminConfigurationView = Loadable(lazy(() => import('pages/admin/configurationview')));
const AdminLog = Loadable(lazy(() => import('pages/admin/log')));
const AdminControls = Loadable(lazy(() => import('pages/admin/controls')));
const AdminCompanyOverridesView = Loadable(lazy(() => import('pages/admin/companyoverridesview')));

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
  path: 'admin',
  element: <DashboardLayout />,
  children: [
    {
      path: 'company',
      children: [
        {
          path: 'list',
          element: <AdminCompany />
        },
        {
          path: ':id',
          element: <AdminCompanyView />,
          children: [
            {
              path: 'overrides',
              element: <AdminCompanyOverridesView />
            }
          ]
        }
      ]
    },
    {
      path: 'user',
      children: [
        {
          path: 'list',
          element: <AdminUser />
        },
        {
          path: ':id',
          element: <AdminUserView />
        }
      ]
    },
    {
      path: 'device',
      children: [
        {
          path: 'list',
          element: <AdminDevice />
        },
        {
          path: ':id',
          element: <AdminDeviceView />
        }
      ]
    },
    {
      path: 'integration',
      children: [
        {
          path: 'list',
          element: <AdminIntegration />
        },
        {
          path: ':id',
          element: <AdminIntegrationView />
        }
      ]
    },
    {
      path: 'configuration',
      children: [
        {
          path: 'list',
          element: <AdminConfiguration />
        },
        {
          path: ':id',
          element: <AdminConfigurationView />
        }
      ]
    },
    {
      path: 'log',
      element: <AdminLog />
    },
    {
      path: 'config',
      element: <AdminControls />
    }
  ]
};

export default AdminRoutes;
